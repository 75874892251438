<template>
  <!-- h5的html代码 -->
  <div v-if="flag" class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <qz-section5 :showHeader="false"></qz-section5>
      <!-- 品牌责任-->
      <div class="yemian two"></div>
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>

  <!-- pc的html代码 -->
  <div v-else class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <qz-section5 :showHeader="false"></qz-section5>
      <more-btn class="more-btn" @getMore="getMoreNews"></more-btn>
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>
</template>
  <script>
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
import Section5 from "../home/components/Section5.vue";
import MoreBtn from "../../components/MoreBtn.vue";
export default {
  name: "Respon",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
    "qz-section5": Section5,
    MoreBtn,
  },

  data() {
    return {
      flag: false,
      page: 1,
      currentIndex: -1,
      imgList: [
        {
          titl: "创新",
          imgUrl:
            "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/respon/pic4.png",
          title: "引领行业品质创新",
        },
        {
          titl: "发展",
          imgUrl:
            "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/respon/pic5.png",
          title: "引领行业未来探索和发展",
        },
        {
          titl: "公益",
          imgUrl:
            "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/respon/pic6.png",
          title: "引领行业创造更多社会价值",
        },
      ],
    };
  },
  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },
  methods: {
    getMoreNews() {
      this.page++;
      console.log("get more", this.page);
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  margin-top: 80px;
}
.more-btn {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  #section5 {
    display: flex;
    margin: 0 10px 10px;
  }
}
</style>
  