<template>
  <div id="section3" class="section fp-section" data-anchor="fourthPage">
    <!-- style="height: 100vh" -->
    <div class="section-con">
      <div class="section3-wrap clearfix">
        <div class="t">
          <div class="c">
            <div class="section3-font">
              <div class="s4-list clearfix">
                <div class="s4-item">
                  <img :src="part1" />
                  <div class="s4-item-ch">国标31888国家执行标准</div>
                  <div class="s4-item-en">
                    <span>GB/T 31888-2015</span>
                    <br />
                    <span>National Executive Standard</span>
                  </div>
                  <p>
                    本标准规定了中小学生校服的技术要求、试验方法、检验规则以及包装、贮运和标志。本标准适用于以纺织织物为主要材料生产的、中小学生在学校日常统一穿着的服装及其配饰。
                  </p>
                </div>
                <div class="s4-item">
                  <img :src="part2" />
                  <div class="s4-item-ch">3M反光技术</div>
                  <div class="s4-item-en" style="line-height: 3em">3M Reflective Technology</div>
                  <p>
                    Scotchlite反光材料——面料是由广角、外露的逆反射性玻璃微珠粘接在各种耐用的布面料背基上制备而成。
                  </p>
                </div>
                <div class="s4-item">
                  <img :src="part3" />
                  <div class="s4-item-ch">无氟防水</div>
                  <div class="s4-item-en" style="line-height: 3em">Fluorine Free Waterproof</div>
                  <p>
                    无氟防水比普通防水具有更高的耐洗性，在水洗之后面料不易变形。
                  </p>
                </div>
              </div>
              <div class="s4-bottom">
                <h4>科技校服，源于关爱</h4>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part1: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-section3-1@2x.webp",
      part2: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-section3-2@2x.webp",
      part3: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-section3-3@2x.webp",
    }
  }
}
</script>