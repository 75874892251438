import axios from 'axios'
import { Notification } from 'element-ui'

// 可能有跨域问题
let api_base_url = "http://qzfscdn.ayouran.com/api"
// let api_base_url = ''
// 创建axios实例
const service = axios.create({
  baseURL: api_base_url,
  timeout: 5000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json' // 关键所在
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    //console.log(res)
    if (res.status !== 'A-1000') { // 后台返回码，根据自己的业务进行修改
      Notification.error({
        title: '错误',
        message: res.message, // 错误描述信息
        duration: 0
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Notification.error({
      title: '错误',
      message: error,
      duration: 0
    })
    return Promise.reject(error)
  }
)

export default service
