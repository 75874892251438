<template>
  <div class="qz-welfare">
    <div class="pageCon">
      <qz-header></qz-header>
      <div
        class="indexfullpage fullpage-wrapper"
        :showServiceForm="showServiceForm"
        :closeServiceForm="closeServiceForm"
      >
        <div class="banner-xian">
          <img
            class="banner"
            src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/banner.webp"
          />
          <div class="lianxi">
            <img
              src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/logo.webp"
              alt=""
            />
          </div>
        </div>
        <div class="pro-item pro-change">
          <div class="inside-container">
            <div class="inside-title">
              <div class="title-en">
                一个人的力量是很难应付生活中无边的苦难的。
                所以，自己需要别人帮助，自己也要帮助别人。
              </div>
              <div class="title-ch">
                <h1>—— 茨威格</h1>
              </div>
            </div>
            <div class="par-img">
              <span>媒体报道</span>
              <div class="module" v-for="video in videos" :key="video">
                <img :src="video.url" /><span class="imgtext">{{
                  video.text
                }}</span>
              </div>
            </div>
            <div class="inside-title">
              <div class="title-en1">
                One's strength is very difficult to cope with the endless misery
                of life. Therefore, they need help from others, and they also
                need to help others.
              </div>
              <div class="title-ch">
                <h1>——Zweig</h1>
              </div>
            </div>
            <div class="par-img">
              <span>{{ SOCIAL_ASSISTANCE }}</span>
              <div class="module1" v-for="picture in pictures" :key="picture">
                <img :src="picture.url" />
              </div>
            </div>
          </div>
          <!-- 客服弹窗 -->
          <div class="mask" v-if="show" @click="show = false"></div>
          <div class="pop" v-if="show">
            <div class="par-img1">
              <div class="ta">
                <div class="con">
                  <el-form
                    class="container"
                    ref="ruleForm"
                    :model="testForm"
                    :rules="rules"
                  >
                    <el-form-item label="您的姓名:" prop="name">
                      <el-input
                        placeholder="公司或个人姓名"
                        v-model="testForm.name"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式:" prop="mobile">
                      <el-input
                        placeholder="请输入手机号"
                        v-model="testForm.mobile"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="问题描述:" prop="description">
                      <el-input
                        class="textarea"
                        type="textarea"
                        v-model="testForm.description"
                        placeholder="请输入您的问题"
                      ></el-input>
                    </el-form-item>
                    <el-form-item class="con1">
                      <el-button type="primary" @click="submitForm('ruleForm')"
                        >提交</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
          <div class="kefu" @click="show = true">
            <span
              ><img
                src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/kefu.webp"
                alt=""
            /></span>
            <div class="ke-text">联系客服</div>
          </div>
        </div>
        <qz-footer></qz-footer>
      </div>
      <div class="qz-service-win">
        <img
          v-if="isMobile"
          class="msg-btn"
          @click="showServiceForm"
          src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/message.png"
          alt=""
        />

        <qz-service
          v-show="formVisible"
          :closeServiceForm="closeServiceForm"
        ></qz-service>
      </div>
      <qz-right-win></qz-right-win>
    </div>
  </div>
</template>
<script>
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
import { afterSale } from "@/api/contact";
import QzService from "../../components/Service.vue";
import { isMobile as checkMobile } from "../../api/device";
import { SOCIAL_ASSISTANCE } from "../../utils/constants";
//import { Notification } from 'element-ui'

export default {
  name: "Welfare",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
    "qz-service": QzService,
  },
  data() {
    return {
      SOCIAL_ASSISTANCE,
      formVisible: false,
      isMobile: checkMobile(),
      testForm: {
        name: "",
        mobile: "",
        description: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", blur: "blur" },
          { min: 1, max: 10, message: "姓名不超过 10 个字符", blur: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", blur: "blur" },
          { min: 11, max: 11, message: "请正确输入手机号", blur: "change" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
            blur: "change",
          },
        ],
        description: [
          { required: true, message: "请输入您的问题", blur: "blur" },
          { min: 1, max: 200, message: "问题不超过 200 个字符", blur: "blur" },
        ],
      },
      show: false,
      videos: [
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/report1.webp",
          text: "应急防汛",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/report2.webp",
          text: "重灾区域消杀 ",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/report3.webp",
          text: "山东阳信方舱",
        },
      ],
      pictures: [
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society1.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society2.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society3.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society4.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society5.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society6.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society7.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society8.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society9.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society10.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society11.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society12.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society13.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society14.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society15.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society16.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society17.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society18.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society19.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society20.webp",
        },
        {
          url: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/welfare/society21.webp",
        },
      ],
    };
  },
  methods: {
    async submitForm(ruleForm) {
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          var postData = {
            name: this.testForm.name, // 姓名
            mobile: this.testForm.mobile, // 联系方式
            description: this.testForm.description,
          };

          let sendResult = await afterSale(postData);
          console.log(sendResult);
          alert("提交成功！");
          // 无论 Notification 还是 Message 都导致提示信息显示在页面底端，样式不对
          //Notification.success({
          //  title: '提交成功！',
          //  message: '请等待客服处理'
          //})
        } else {
          console.log("提交失败！");
          return false;
        }
      });
    },
    showServiceForm() {
      if (this.isMobile) {
        this.$router.push("/service");
      } else {
        this.formVisible = true;
      }
    },
    closeServiceForm() {
      this.formVisible = false;
    },
  },
};
</script>
<style >
.qz-welfare
  .pop
  .par-img1
  .con
  .container
  .el-form-item.is-error.is-required
  /deep/
  .el-input__inner {
  border: 2px solid red;
}
.el-form-item.is-error.is-required /deep/ .el-form-item__error {
  color: red;
  font-size: 8px;
}
.qz-welfare
  .pop
  .par-img1
  .con
  .container
  .el-form-item.is-error
  /deep/
  .el-textarea__inner {
  border: 2px solid red;
}
</style>
<style lang="scss">
@import "../../../node_modules/swiper/dist/css/swiper.css";
@import "./index.scss";
</style>
