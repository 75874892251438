import VueRouter from "vue-router";
import Vue from "vue";

import Demo from "../views/demo/index.vue";
import Layout from "../views/layout.vue";
import Home from "../views/home/index.vue";

import Vision from "../views/vision/index.vue";
import Respon from "../views/respon/index.vue";
import Original from "../views/original/index.vue";
import Product from "../views/ProductsWell/index.vue";
import Partner from "../views/CooperativePartner/index.vue";
import Contact from "../views/ContactUs/index.vue";
import Welfare from "../views/ShuguangWelfare/index.vue";
import News from "../views/News/index.vue"
import NewDetail from "../views/News/detail.vue"

const preRoutes = [
    {
        path: "/",
        name: "Layout",
        component: Layout,
        children: [
            {path: "demo", name: "Demo", component: Demo, meta: "Demo Page"},
            {
                path: "home",
                name: "Home",
                alias: "/",
                component: Home,
                meta: "Home Page",
            },
        ],
    },
    {
        path: "/service",
        name: "Service",
        component: () => import("@/views/service/index.vue"),
        meta: "Service Form",
    },
    {
        path: "/Product",
        name: "Product",
        component: Product,
        meta: "Product Page",
    },
    {
        path: "/Partner",
        name: "Partner",
        component: Partner,
        meta: "Partner Page",
    },
    {
        path: "/Contact",
        name: "Contact",
        component: Contact,
        meta: "Contact Page",
    },
    {
        path: "/Welfare",
        name: "Welfare",
        component: Welfare,
        meta: "Welfare Page",
    },
    {path: "/vision", name: "Vision", component: Vision, meta: "Vision Page"},
    {path: "/respon", name: "Respon", component: Respon, meta: "Respon Page"},
    {
        path: "/original",
        name: "Original",
        component: Original,
        meta: "Original Page",
    },
    {
        path: "/news",
        name: "News",
        component: News,
        meta: "News Page",
        children: [{
            path: "/detail", name: "NewDetail", component: NewDetail, meta: "NewDetail Page",
        }]
    },
    {
        path: "/news/:id",
        name: "NewDetail",
        component: NewDetail,
        meta: "News Page"
    },
];

// 后台
const adminRoutes = [
    {
        path: "/admin",
        name: "Layout",
        component: () => import('@/views/admin/components/Layout.vue'),
        redirect: "/admin/index",
        children: [
            {
                path: "index",
                name: "Index",
                component: () => import('@/views/admin/set/home.vue'),
                meta: "adminHome Page",
            },
            {
                path: "welfare",
                name: "Welfare",
                component: () => import('@/views/admin/set/welfare.vue'),
                meta: "welfare Page",
            },
            {
                path: "respon",
                name: "Respon",
                component: () => import('@/views/admin/set/respon.vue'),
                meta: "respon Page",
            },
            {
                path: "original",
                name: "Original",
                component: () => import('@/views/admin/set/original.vue'),
                meta: "original Page",
            },
            {
                path: "news",
                name: "NewsIndex",
                component: () => import('@/views/admin/news/index.vue'),
                meta: "news Page",
            },
            {
                path: "news/add",
                name: "NewsAdd",
                component: () => import('@/views/admin/news/add.vue'),
                meta: "news Page",
            },
            {
                path: "inventory",
                name: "Inventory",
                component: () => import('@/views/admin/inventory/dictionary1.vue'),
                meta: "dictionary Page",
            },
            {
                path: "inventory/index",
                name: "indexInventory",
                component: () => import('@/views/admin/inventory/index.vue'),
                meta: "indexInventory Page",
            }
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('@/views/admin/login.vue'),
        meta: "adminHome Page",
    },
]

const routes = [...preRoutes, ...adminRoutes]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    mode: "history",
    routes, // (缩写) 相当于 routes: routes
});

router.afterEach(() => window.scrollTo(0, 0));
// 全局前置守卫，用于路由拦截
router.beforeEach((to, from, next) => {
    // 这里可以添加路由拦截的逻辑
    // 例如，检查用户是否登录
    if (to.path === '/') {
        next("/home");
    } else {
        next(); // 对其他路由，直接继续导航
    }
})
Vue.use(VueRouter);

export default router;
