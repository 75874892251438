<template>
  <!-- <div class="qz-home">
    <div class="pageCon">
      <qz-header></qz-header>
      <div
        class="indexfullpage fullpage-wrapper"
        style="
          height: 100%;
          position: relative;
          touch-action: none;
          transition: all 0ms ease 0s;
          transform: translate3d(0px, 0px, 0px);
        "
      >
        <qz-section1></qz-section1>
        <qz-section2></qz-section2>
        <qz-section3></qz-section3>
        <qz-section4></qz-section4>
        <qz-section5></qz-section5>
        <qz-footer></qz-footer>
      </div>
      <qz-right-win></qz-right-win>
    </div>
  </div> -->
  <div>
    <qz-section1 :showServiceForm="showServiceForm"></qz-section1>
    <qz-section2></qz-section2>
    <qz-section3></qz-section3>
    <qz-section4></qz-section4>
    <qz-section5 :showHeader="true"></qz-section5>
  </div>
</template>

<script>
import QzSection1 from "./components/Section1.vue";
import QzSection2 from "./components/Section2.vue";
import QzSection3 from "./components/Section3.vue";
import QzSection4 from "./components/Section4.vue";
import QzSection5 from "./components/Section5.vue";

import { isMobile } from "@/api/device";

// import QzHeader from "../../components/Header.vue";
// import QzFooter from "../../components/Footer.vue";
// import QzRightWin from "../../components/RightWin.vue";
// import fullpage from "../../fullpage/index.js";

export default {
  name: "Home",
  components: {
    "qz-section1": QzSection1,
    "qz-section2": QzSection2,
    "qz-section3": QzSection3,
    "qz-section4": QzSection4,
    "qz-section5": QzSection5,
    // "qz-footer": QzFooter,
    // "qz-header": QzHeader,
    // "qz-right-win": QzRightWin,
  },
  props: ["showServiceForm"],
  mounted() {
    // this.$nextTick(() => {
    //   new fullpage('.indexfullpage', {
    //     //options here
    //     autoScrolling: true,
    //     scrollHorizontally: true
    //   });
    // })
    this.$nextTick(() => {
      try {
        this.initVideoAutoPlay();
      } catch (error) {
        console.error(error);
      }
    });
  },
  methods: {
    initVideoAutoPlay() {
      const videos = document.querySelectorAll("video");
      if (!videos || videos.length == 0) {
        return;
      }
      if (isMobile()) {
        const config = {
          rootMargin: "0px",
          threshold: 0,
        };
        let observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            try {
              let video = entry.target;
              if (entry.isIntersecting) {
                !video.ended && video.paused && video.play();
              } else {
                !video.paused && video.pause();
              }
            } catch (error) {
              console.error(error);
            }
          });
        }, config);
        videos.forEach((video) => {
          observer.observe(video);
        });
      } else if (videos.length > 1) {
        videos[1].play();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/swiper/dist/css/swiper.css";
@import "./index.scss";

.qz-home {
  .section-con {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 8;
  }
}
</style>