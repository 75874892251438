<template>
  <!-- h5的html代码 -->
  <div v-if="flag" class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <div class="yemian one">
        <img class="message" src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/message.png" />
      </div>
      <!-- 品牌初心-->
      <div class="yemian two">
        <div class="inside-title">
          <div class="title-en">
            <span class="line line1"></span>Brand Initial Belief<span
              class="line line2"
            ></span>
          </div>
          <div class="title-ch">
            <h1>品牌初心</h1>
          </div>
        </div>
        <div class="inside">
          <div class="picture" style="display: flex">
            <img src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/pic1.png" />
            <p>
              严把质量关的同时琪志人同样注重校服款式的创新，历来中国的校服给人的印象是宽大、松垮毫无时尚可言，随着人们审美水平的不断提高校服的款式也在不断的改善、创新，一步一步向着时尚、美观、合身的方向发展，我们推出了休闲时尚的运动风校服、修身合体的正装款校服、典雅大方的传统款校服，同时公司设计组在校服细节设计上加入了反光条，反光条是种常见的安全设备，能在晚间、起雾时等视线不好的时候，反射周围的光线，对行人和司机起到一定的警示作用；校服上的反光条更具备耐磨性，经长时间的洗涤冲刷，仍然能保持百分之八十的反光能力；定制校服的反光条设计不仅让款式更加时尚精美，还能为孩子的出行增添一份安全保障。
            </p>
            <img class="inner" src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/pic2.png" />
            <p>
              2020年琪志服饰联合国内多家科技公司共同研制出一套适合学校的学生安全智能校服管理系统，采用个人信息技术,在学校出入口安装基站,学生进出时将被读取。智能读取系统将学生信息上传至系统服务器,系统经过处理后可判断具体信息(如:人员姓名,所处位置,出现时间等),同时将学生入校、离校信息保存在服务器中,供老师下载。系统服务器也可根据上学、放学该段时间内出入学校大门的人员出入信息，整理出这一时间段内的各种出勤报表(如:迟到/早退记录、未到校的时间等)。该系统利用高科技的远距离射频识别技术和计算机信息处理技术开发而成的具有世界领先水平的综合信息管理系统。为广大中小学校、学生家长提供了信息化服务平台。琪志平安智能校服的开拓应用，升级了传统校服的功能，孩子们进出校园的动向，家校自动感知，互联互通，避免了沟通不畅，减轻了老师工作压力；同时，无感智能打卡出入，避免了疫情期间接触感染的机会和陌生拜访。提高了管理效率
              ，确保了校园环境的安全，为学生筑起了安全防护墙。
            </p>
            <img src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/pic3.png" />
            <p>
              如今,校服已经不只是学校的一种标志,它更是校服是校园文化的载体，是学生青春时代一份不可或缺的回忆。质量强国的今天,随着人民生活水平的提高,消费意识在逐步提高,二胎放开后,孩子数量将成倍增长。国内校服市场的规范刚刚开始,校服品质的提升,更需要有担当的校服企业引领!我们希望,未来,有更多的校服企业,能够永远把孩子的安全放在第一位,将校服质量作为第一目标,让孩子们拥有一份美好的校服回忆。
            </p>
          </div>
        </div>
      </div>
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>
  <!-- pc的html代码 -->
  <div v-else class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <div class="yemian one"></div>
      <!-- 品牌初心-->
      <div class="yemian two">
        <div class="inside-title">
          <div class="title-en">
            <span class="line line1"></span>Brand Initial Belief<span
              class="line line2"
            ></span>
          </div>
          <div class="title-ch">
            <h1>品牌初心</h1>
          </div>
        </div>
        <div class="inside">
          <div class="picture" style="display: flex">
            <img src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/pic1.png" />
            <img class="inner" src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/pic2.png" />
            <img src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/pic3.png" />
          </div>
          <p>
            严把质量关的同时琪志人同样注重校服款式的创新，历来中国的校服给人的印象是宽大、松垮毫无时尚可言，随着人们审美水平的不断提高校服的款式也在不断的改善、创新，一步一步向着时尚、美观、合身的方向发展，我们推出了休闲时尚的运动风校服、修身合体的正装款校服、典雅大方的传统款校服，同时公司设计组在校服细节设计上加入了反光条，反光条是种常见的安全设备，能在晚间、起雾时等视线不好的时候，反射周围的光线，对行人和司机起到一定的警示作用；校服上的反光条更具备耐磨性，经长时间的洗涤冲刷，仍然能保持百分之八十的反光能力；定制校服的反光条设计不仅让款式更加时尚精美，还能为孩子的出行增添一份安全保障。<br />
            <span> &nbsp; &nbsp;&nbsp;&nbsp;</span
            >2020年琪志服饰联合国内多家科技公司共同研制出一套适合学校的学生安全智能校服管理系统，采用个人信息技术,在学校出入口安装基站,学生进出时将被读取。智能读取系统将学生信息上传至系统服务器,系统经过处理后可判断具体信息(如:人员姓名,所处位置,出现时间等),同时将学生入校、离校信息保存在服务器中,供老师下载。系统服务器也可根据上学、放学该段时间内出入学校大门的人员出入信息，整理出这一时间段内的各种出勤报表(如:迟到/早退记录、未到校的时间等)。该系统利用高科技的远距离射频识别技术和计算机信息处理技术开发而成的具有世界领先水平的综合信息管理系统。为广大中小学校、学生家长提供了信息化服务平台。琪志平安智能校服的开拓应用，升级了传统校服的功能，孩子们进出校园的动向，家校自动感知，互联互通，避免了沟通不畅，减轻了老师工作压力；同时，无感智能打卡出入，避免了疫情期间接触感染的机会和陌生拜访。提高了管理效率
            ，确保了校园环境的安全，为学生筑起了安全防护墙。<br />
            <span> &nbsp; &nbsp; &nbsp;&nbsp;</span
            >如今,校服已经不只是学校的一种标志,它更是校服是校园文化的载体，是学生青春时代一份不可或缺的回忆。质量强国的今天,随着人民生活水平的提高,消费意识在逐步提高,二胎放开后,孩子数量将成倍增长。国内校服市场的规范刚刚开始,校服品质的提升,更需要有担当的校服企业引领!我们希望,未来,有更多的校服企业,能够永远把孩子的安全放在第一位,将校服质量作为第一目标,让孩子们拥有一份美好的校服回忆。
          </p>
        </div>
      </div>
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>
</template>
<script>
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
export default {
  name: "Original",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
  },
  data() {
    return {
      flag: false,
    };
  },
  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },
};
</script>
<style lang="scss">
@import "../../../node_modules/swiper/dist/css/swiper.css";
.qz-contact {
  .pageCon {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 8;
  }
  .one {
    width: 100%;
    height: 810px;
    background: url("https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/bg1.png") no-repeat center /
      cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .one > img {
    width: 834px;
    height: 146px;
  }
  .two {
    width: 100%;
    background: #e7e7e3;
    position: relative;
  }
  .inside-title {
    padding-top: 35px;
    padding-bottom: 46px;
  }
  .inside {
    display: flex;
    color: #5a1b1b;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .picture .inner {
    margin: 0 109px;
  }
  .picture img {
    width: 302px;
    height: 178px;
  }
  .inside p {
    width: 1133px;
    font-size: 16px;
    line-height: 29px;
    text-indent: 2em;
    margin-top: 44px;
    margin-bottom: 86px;
  }
  .inside-title .title-en .line {
    width: 58px;
    height: 1px;
    background: #5a1b1b;
    display: inline-block;
    vertical-align: middle;
    margin: 0 38px;
  }
  .inside-title .title-en,
  .inside-title .title-ch {
    text-align: center;
    font-size: 23px;
    font-weight: normal;
    color: #5a1b1b;
    line-height: 48px;
  }
}
@media screen and (max-width: 768px) {
    .main .one{
    height: 700px;
      background: url("https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/original/h5bg.png");
       background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
    }
  .main .one .message {
    width: 65.5px;
    height: 65.5px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .inside,
  .picture {
    flex-direction: column;
  }
  .inside .picture p {
    width: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
  .inside .picture img {
    margin: 0 auto;
  }
  .inside .picture .inner {
    margin: 0 auto;
  }
  .qz-contact .two .inside-title {
    padding-bottom: 35px;
    .title-en,
    .title-ch {
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
    }
  }
}
</style>
