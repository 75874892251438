import request from '../utils/request'

async function sendRequest(params) {
  const result = await request({
    url: '/msg/contact',
    method: 'post',
    contentType: "application/json:charset=UTF-8",
    data: params,
  })
  return result && result.data;
}

async function afterSale(params) {
  const result = await request({
    url: '/msg/after_sale',
    method: 'post',
    contentType: "application/json:charset=UTF-8",
    data: params,
    // data: JSON.stringify(params),
  })
  return result && result.data;
}

export { sendRequest, afterSale };
