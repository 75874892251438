import moment from "moment";
export const randomDate = () => {
    const  randomMonthNum = Math.floor(Math.random() * 11 );
    const  randomDateNum = Math.floor(Math.random() * 30 );
    const  randomHourNum =  Math.floor(Math.random() * 24 );
    const  randomMinuteNum = Math.floor(Math.random() * 60 );
    return moment()
            .month(randomMonthNum)
            .date(randomDateNum)
            .hour(randomHourNum)
            .minutes(randomMinuteNum)
            .second(randomMinuteNum)
            .format('YYYY年MM月DD')
}