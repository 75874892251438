<template>
  <div class="qz-right-win">
    <div class="rightWrap">
      <div class="rightCon">
        <ol>
          <li>
            <a href="/Contact" class="r-kf">
              <img :src="rwContact" alt="" />
            </a>
          </li>
          <li>
            <a href="https://shop.m.taobao.com/shop/shop_index.htm?user_id=273141135&item_id=607914423182" class="r-kf" target="_blank">
              <img :src="rwMail" alt="" />
            </a>
          </li>
          <li>
            <a href="/Contact" class="r-kf">
              <img :src="rwHelp" alt="" />
            </a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rwContact: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/rw-contact@2x.webp",
      rwHelp: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/rw-help@2x.webp",
      rwMail: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/rw-mail@2x.webp",
    }
  }
}
</script>

<style lang="scss">
$theme: #5a1b1b;
$themeBg: $theme;
$themeSwiperBg: #845953;
$rightBg: $themeBg;
$rightHoverBg: $rightBg;
$rightDivider: #440c0c;

.qz-right-win {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 8;

  .rightWrap {
    background: $rightBg;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden;
  }

  .rightWrap .rightCon ol li {
    &:last-child a {
      border: none;
    }
  }

  .rightWrap .rightCon ol li:hover {
    background: $rightHoverBg;
  }

  .rightWrap .rightCon ol li a {
    display: block;
    width: 43px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding: 15px 5px;
    border-bottom: 1px solid $rightDivider;
    background-size: 30px;
    background-position: center 16px;
    background-repeat: no-repeat;
    box-sizing: border-box;

    img {
      width: 33px;
      height: 33px;
    }
  }
}
</style>