<template>
  <div
    id="section2"
    class="section fp-section active fp-completely"
    data-anchor="thirdPage"
  >
    <!-- style="height: 100vh" -->
    <div class="section-con">
      <div class="section2-wrap">
        <div class="section2-map">
          <div class="t">
            <div class="c">
              <div class="s3-map-con" style="width: 100%">
                <video
                  id="index-player1"
                  controls
                  muted="true"
                  :poster="videoCover"
                >
                  <source
                    type="video/mp4"
                    src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/shouye123.mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
        <div class="section2-font">
          <div class="t">
            <div class="c">
              <div class="s3-txt-con">
                <div class="section-tit-en white">
                  Uniform solution for famous school
                </div>
                <div class="section-tit-ch white">名校美育巡礼</div>
                <p class="white">
                  引领中国校园服饰变革，推动共同教育价值重建。
                </p>
                <ol class="clearfix">
                  <li class="white">
                    <span id="counter1" data-val="26" class="sp-num white"
                      >26</span
                    ><span class="sp-type white">省级分布</span>
                  </li>
                  <li class="white">
                    <span id="counter2" data-val="150" class="sp-num white"
                      >150</span
                    ><span class="sp-type white">城市分布</span>
                  </li>
                  <li class="white">
                    <span id="counter3" data-val="1663" class="sp-num white"
                      >1663</span
                    ><span class="sp-type white">合作学校</span>
                  </li>
                  <li class="white">
                    <span id="counter4" data-val="2164100" class="sp-num white"
                      >2164100</span
                    ><span class="sp-type white">服务学生</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoCover: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/contact/shouyevideo.jpeg",
    }
  },
}
</script>

<style lang="scss">
#index-player1 {
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}
</style>