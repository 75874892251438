<template>
  <div class="qz-Product">
    <div class="pageCon">
      <qz-header></qz-header>
      <div
        class="indexfullpage fullpage-wrapper"
        :showServiceForm="showServiceForm"
        :closeServiceForm="closeServiceForm"
      >
        <div class="qz-banner">
          <img
            class="banner"
            src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/banner.webp"
          />
        </div>
        <div class="pro-item pro-change">
          <!-- 春季系列 -->
          <div
            class="inside-container"
            v-for="product in products"
            :key="product"
          >
            <div class="inside-title">
              <div class="title-en">
                <span class="line line1"></span>{{ product.texten
                }}<span class="line line2"></span>
              </div>
              <div class="title-ch">
                <h1>{{ product.textch }}</h1>
              </div>
            </div>
            <div class="pro-spring">
              <img :src="product.miandrawing" />
              <div
                class="springmin"
                v-for="index in product.attacheddrawing"
                :key="index"
              >
                <img :src="index.img" />
                <img :src="index.img1" />
                <img :src="index.img2" />
                <img :src="index.img3" />
              </div>
            </div>
            <span class="line"></span>
          </div>
          <more-btn url="/respon"></more-btn>
        </div>
        <qz-footer></qz-footer>
      </div>
      <div class="qz-service-win">
        <img
          v-if="isMobile"
          class="msg-btn"
          @click="showServiceForm"
          src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/message.png"
          alt=""
        />

        <qz-service
          v-show="formVisible"
          :closeServiceForm="closeServiceForm"
        ></qz-service>
      </div>
      <qz-right-win></qz-right-win>
    </div>
  </div>
</template>
<script>
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
import QzService from "../../components/Service.vue";
import { isMobile as checkMobile } from "../../api/device";
import MoreBtn from "../../components/MoreBtn.vue";
export default {
  name: "Product",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
    "qz-service": QzService,
    MoreBtn,
  },
  data() {
    return {
      formVisible: false,
      isMobile: checkMobile(),
      products: [
        {
          texten: "Spring Series",
          textch: "春季系列",
          miandrawing:
            "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/spring.webp",
          attacheddrawing: [
            {
              img: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/spring1.webp",
              img1: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/spring2.webp",
              img2: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/spring3.webp",
              img3: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/spring4.webp",
            },
          ],
        },
        {
          texten: "Summer Series",
          textch: "夏季系列",
          miandrawing:
            "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/summer.webp",
          attacheddrawing: [
            {
              img: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/summer1.webp",
              img1: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/summer2.webp",
              img2: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/summer3.webp",
              img3: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/summer4.webp",
            },
          ],
        },
        {
          texten: "Autumn Series",
          textch: "秋季系列",
          miandrawing:
            "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/autumn.webp",
          attacheddrawing: [
            {
              img: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/autumn1.webp",
              img1: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/autumn2.webp",
              img2: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/autumn3.webp",
              img3: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/autumn4.webp",
            },
          ],
        },
        {
          texten: "Winter Series",
          textch: "冬季系列",
          miandrawing:
            "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/winter.webp",
          attacheddrawing: [
            {
              img: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/winter1.webp",
              img1: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/winter2.webp",
              img2: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/winter3.webp",
              img3: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/product/winter4.webp",
            },
          ],
        },
      ],
    };
  },
  methods: {
    showServiceForm() {
      if (this.isMobile) {
        this.$router.push("/service");
      } else {
        this.formVisible = true;
      }
    },
    closeServiceForm() {
      this.formVisible = false;
    },
  },
};
</script>
<style lang="scss">
@import "../../../node_modules/swiper/dist/css/swiper.css";
@import "./index.scss";
.more {
  text-align: center;
}
</style>