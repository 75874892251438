<template>
  <div class="qz-service-pop">
    <div class="mask" @click="close"></div>
    <div class="body">
      <service-form ref="formComp"></service-form>
    </div>
  </div>
</template>

<script>
import ServiceForm from "./ServiceForm.vue";

export default {
  name: "ServiceModal",
  components: {
    "service-form": ServiceForm
  },
  props: ["closeServiceForm"],
  methods: {
    close() {
      this.closeServiceForm();
      this.$refs.formComp?.$refs.afterSaleForm && this.$refs.formComp.$refs.afterSaleForm.resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
.qz-service-pop {
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
  }
  .body {
    position: fixed;
    background: #fff;
    bottom: 0;
    right: 0;
    z-index: 20;
    width: 274px;
    padding: 16px;
    border: 1px solid #5a1b1b;
  }
}
</style>
