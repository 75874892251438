<template>
  <div class="h-screen">DEMO</div>
</template>

<script>
export default {
  name: "demo",
}
</script>

<style scoped>
.h-screen {
  height: 100vh;
  padding-top: 60px;
}
</style>