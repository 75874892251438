<template>
  <div class="more">
    <a type="button" class="more-btn" :href="url" @click="showMore">{{
      SHOW_MORE
    }}</a>
  </div>
</template>
<script>
import { SHOW_MORE } from "../utils/constants";
export default {
  props: ["url"],
  data() {
    return { SHOW_MORE };
  },
  methods: {
    showMore() {
      if (!this.url) {
        this.$emit("getMore");
      }
    },
  },
};
</script>
<style lang="scss">
$theme: #5a1b1b;
.more {
  .more-btn {
    line-height: 38px;
    border-radius: 38px;
    border: 1px solid $theme;
    color: $theme;
    font-size: 14px;
    text-align: center;
    padding: 8px 29px;
    cursor: pointer;
  }
}
</style>