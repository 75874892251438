<template>
  <div id="section4" class="section fp-section" data-anchor="fifthPage">
    <!-- style="height: 100vh" -->
    <div class="section-con">
      <div class="section4-wrap">
        <div class="section4-img">
          <video
            id="index-player2"
            data-autoplay=""
            controls
            muted="true"
            :poster="videoCover"
          >
            <source
              type="video/mp4"
              src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/pinpaizeren.mp4"
            />
          </video>
          <div class="section4-img-brief">
            解决当前校服突出质量安全问题为出发点，为教育主管部门制定校服管理办法提供标准依据，同时为校服各相关方更加方便地使用标准提供更为明确的指向。
          </div>
        </div>
        <div class="section4-font">
          <div class="t">
            <div class="c">
              <div class="section-tit-en">Responsibility</div>
              <div class="section-tit-ch">品牌责任</div>
              <ul>
                <li v-for="newsItem in newsList" :key="newsItem.title">
                  <div class="new-type-tit">{{ newsItem.title }}</div>
                  <p>
                    <a :href="newsItem.url" target="_blank" class="">{{
                      newsItem.brief
                    }}</a
                    ><span>{{ newsItem.createdAt }}</span>
                  </p>
                </li>
              </ul>
              <more-btn url="/respon"></more-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreBtn from "../../../components/MoreBtn.vue";
import { randomDate } from "../../../utils/date";
export default {
  components: {
    MoreBtn,
  },
  data() {
    return {
      newsList: [],
      part1:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-section4-1@2x.webp",
      videoCover:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-video2.jpeg",
    };
  },
  async created() {
    // this.newsList = await this.fetchNewsList();
    this.newsList = [
      {
        title: "品牌责任",
        brief: "琪志服饰责任感",
        createdAt: randomDate(),
        url: "/respon",
      },
      {
        title: "社会责任",
        brief: "琪志参与公益救援活动",
        createdAt: randomDate(),
        url: "/Welfare",
      },
      {
        title: "新闻资讯",
        brief: "第八届中国行业影响力品牌峰会",
        createdAt: randomDate(),
        url: "https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkyNzA2ODc4Ng==&action=getalbum&album_id=2386096229111054337#wechat_redirect",
      },
    ];
  },
  // methods: {
  //   async fetchNewsList() {
  //     const newsList = [
  //       {
  //         title: "品牌责任",
  //         brief: "琪志服饰责任感",
  //         createdAt: "2022-04-22T00:00:00.000Z",
  //         url: ""
  //       },
  //       {
  //         title: "社会责任",
  //         brief: "琪志参与公益救援活动",
  //         createdAt: "2022-04-22T00:00:00.000Z",
  //         url: ""
  //       },
  //       {
  //         title: "新闻资讯",
  //         brief: "第八届中国行业影响力品牌峰会",
  //         createdAt: "2022-04-22T00:00:00.000Z",
  //         url: ""
  //       },
  //     ];
  //     return new Promise((resolve) => {
  //       setTimeout(() => {
  //         resolve(newsList)
  //       }, 1000);
  //     })
  //   }
  // }
};
</script>

<style lang="scss">
$themeSwiperBg: #845953;

#section4 {
  .section-con {
    margin-top: 80px;
  }
}

.section4-img {
  // float: left;
  width: 50%;

  // position: relative;
  // top: 50%;
  // transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100%;
    // height: 272px;
  }
  // .section4-img-img {
  //   height: 272px;
  //   background: url("https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-section4-1@2x.webp") no-repeat
  //     bottom right / cover;
  // }

  .section4-img-brief {
    // height: 194px;
    font-size: 15px;
    color: #fff;
    text-align: justify;
    padding: 43px 46px 40px;
    background: $themeSwiperBg;
    line-height: 2;
  }
}

.section4-wrap {
  .more {
    display: none;
    padding-top: 34px;
    padding-bottom: 52px;
  }
}
</style>