<template>
  <!-- h5的html代码 -->
  <div v-if="flag" class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <div class="yemian one">
       
        <img class="inner" src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/pic1.png" />
        <img class="message" src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/message.png" />

      </div>

      <!-- 使命与愿景 -->
      <div class="yemian two">
        <div class="inside-title">
          <div class="title-en">
            <span class="line line1"></span>Mission & Vision<span
              class="line line2"
            ></span>
          </div>
          <div class="title-ch">
            <h1>使命和愿景</h1>
          </div>
        </div>
        <div class="inside">
          <div class="inside-left">
            <span>Mission</span>
            <h1>使命</h1>
            <p>
              致力于中国教育校园服饰礼仪、人文教育、品格之美的传承者和建设者。
            </p>
          </div>
          <div class="inside-right">
            <span>Vision</span>
            <h1>愿景</h1>
            <p>成为全球校园服饰品牌的领导者和未来教育之美的创造者</p>
          </div>
        </div>
      </div>
      <!-- 未来教育 -->
      <div class="yemian three">
        <div class="inside-title">
          <div class="title-en">
            <span class="line line1"></span>Future Education<span
              class="line line2"
            ></span>
          </div>
          <div class="title-ch">
            <h1>未来教育</h1>
          </div>
        </div>
        <p>
          琪志服饰，与中国教育共同进步，在未来教育的探索上，不断锐意进步，探索校服与校园“健康之美”、“人格之美”、“品行之美”、“发展之美”等内在理念和服饰之美的结合。
        </p>
        <div class="box">
          <div class="line3">
            <div class="plat">
              <div class="platoon" v-for="(item, index) in List" :key="index">
                <div class="small"></div>
                <img src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/xx.png" />
                <div class="big">
                  <span>{{ List[index].titl }}</span>
                </div>
                <div class="wenzi">
                <div class="wenzi1">
                  <span>{{ List[index].yingwen1 }}</span>
                  <span>{{ List[index].zhongwen1 }}</span>
                </div>
                <div class="wenzi2">
                  <span>{{ List[index].yingwen2 }}</span>
                  <span>{{ List[index].zhongwen2 }}</span>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>

  <!-- pc的html代码 -->
  <div v-else class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <div class="yemian one">
        <img class="inner" src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/pic1.png" />
      </div>
      <!-- 使命与愿景 -->
      <div class="yemian two">
        <div class="inside-title">
          <div class="title-en">
            <span class="line line1"></span>Mission & Vision<span
              class="line line2"
            ></span>
          </div>
          <div class="title-ch">
            <h1>使命和愿景</h1>
          </div>
        </div>
        <div class="inside">
          <div class="inside-left">
            <span>Mission</span>
            <h1>使命</h1>
            <p>
              致力于中国教育校园服饰礼仪、人文教育、品格之美的传承者和建设者。
            </p>
          </div>
          <div class="inside-right">
            <span>Vision</span>
            <h1>愿景</h1>
            <p>成为全球校园服饰品牌的领导者和未来教育之美的创造者</p>
          </div>
        </div>
      </div>
      <!-- 未来教育 -->
      <div class="yemian three">
        <div class="inside-title">
          <div class="title-en">
            <span class="line line1"></span>Future Education<span
              class="line line2"
            ></span>
          </div>
          <div class="title-ch">
            <h1>未来教育</h1>
          </div>
        </div>
        <p>
          琪志服饰，与中国教育共同进步，在未来教育的探索上，不断锐意进步，探索校服与校园“健康之美”、“人格之美”、“品行之美”、“发展之美”等内在理念和服饰之美的结合。
        </p>
        <div class="box">
          <div class="line3">
            <div class="plat">
              <div class="platoon" v-for="(item, index) in List" :key="index">
                <div class="small"></div>
                <img src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/shape.png" />
                <div class="big">
                  <span>{{ List[index].titl }}</span>
                </div>
                <div class="wenzi1">
                  <span>{{ List[index].yingwen1 }}</span>
                  <span>{{ List[index].zhongwen1 }}</span>
                </div>
                <div class="wenzi2">
                  <span>{{ List[index].yingwen2 }}</span>
                  <span>{{ List[index].zhongwen2 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>
</template>
<script>
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
export default {
  name: "Vision",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
  },
  data() {
    return {
      flag: false,
      List: [
        {
          titl: "中西合璧",
          yingwen1: "Well-balanced",
          zhongwen1: "全面发展的人",
          yingwen2: "Reflective",
          zhongwen2: "善于反思的人",
        },
        {
          titl: "知行合一",
          yingwen1: "Inquirers",
          zhongwen1: "探究者",
          yingwen2: "Thinkers",
          zhongwen2: "思考者",
        },
        {
          titl: "寓教于乐",
          yingwen1: "Communicatiors",
          zhongwen1: "交流者",
          yingwen2: "Risk takers",
          zhongwen2: " 敢于冒险的人",
        },
        {
          titl: "美育贯穿",
          yingwen1: "Principled",
          zhongwen1: "有原则的人",
          yingwen2: "Knowledgeable",
          zhongwen2: "知识渊博的人",
        },
        {
          titl: "跨越学科",
          yingwen1: "Open-minded",
          zhongwen1: "心胸宽广的人",
          yingwen2: "Caring",
          zhongwen2: "富有同情心的",
        },
      ],
    };
  },
  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },
};
</script>
<style scoped lang="scss">
@import "../../../node_modules/swiper/dist/css/swiper.css";
@import "./index.scss";
</style>
