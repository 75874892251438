<template>
  <div class="qz-partner">
    <div class="pageCon">
      <qz-header></qz-header>
      <div
        class="indexfullpage fullpage-wrapper"
        :showServiceForm="showServiceForm"
        :closeServiceForm="closeServiceForm"
      >
        <div class="pro-item pro-change">
          <div class="inside-container">
            <div class="inside-title">
              <div class="title-en">
                <span class="line line1"></span>cooperative Partner<span
                  class="line line2"
                ></span>
              </div>
              <div class="title-ch">
                <h1>合作伙伴</h1>
              </div>
            </div>
            <div class="par-img">
              <img src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/partner/logo.webp" />
            </div>
          </div>
        </div>
        <qz-footer></qz-footer>
      </div>
      <div class="qz-service-win">
        <img
          v-if="isMobile"
          class="msg-btn"
          @click="showServiceForm"
          src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/message.png"
          alt=""
        />

        <qz-service
          v-show="formVisible"
          :closeServiceForm="closeServiceForm"
        ></qz-service>
      </div>
      <qz-right-win></qz-right-win>
    </div>
  </div>
</template>
<script>
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
import QzService from "../../components/Service.vue";
import { isMobile as checkMobile } from "../../api/device";
export default {
  name: "Partner",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
    "qz-service": QzService,
  },
  data() {
    return {
      formVisible: false,
      isMobile: checkMobile(),
    };
  },
  methods: {
    showServiceForm() {
      if (this.isMobile) {
        this.$router.push("/service");
      } else {
        this.formVisible = true;
      }
    },
    closeServiceForm() {
      this.formVisible = false;
    },
  },
};
</script>
<style lang="scss">
@import "../../../node_modules/swiper/dist/css/swiper.css";
@import "./index.scss";
</style>