<template>
  <div class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
      <div
        class="indexfullpage fullpage-wrapper"
        :showServiceForm="showServiceForm"
        :closeServiceForm="closeServiceForm"
      >
        <div class="banner-xian">
          <img
            class="banner"
            src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/contact/map1.webp"
          />
          <div class="lianxi">
            <span
              >名称：上海琪志服饰有限公司<br />地址：上海区浦东新区施湾二路688号
              <br />固话：021-58598570<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;400-0676-580
            </span>
            <img :src="erweima" alt="" />
          </div>
        </div>
        <div class="pro-item pro-change">
          <!-- 春季系列 -->
          <div class="inside-container">
            <div class="inside-title">
              <div class="title-en">
                <span class="line line1"></span>Contact us<span
                  class="line line2"
                ></span>
              </div>
              <div class="title-ch">
                <h1>联系我们</h1>
              </div>
            </div>
            <div class="par-img">
              <div class="ta">
                <img
                  src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/contact/map2.webp"
                />
                <span class="line3"></span>
                <div class="con">
                  <el-form
                    class="container"
                    ref="ruleForm"
                    :model="testForm"
                    :rules="rules"
                  >
                    <el-form-item label="您的姓名:" prop="name">
                      <el-input
                        placeholder="公司或个人姓名"
                        v-model="testForm.name"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式:" prop="mobile">
                      <el-input
                        placeholder="请输入手机号"
                        v-model="testForm.mobile"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="订购需求：" prop="demand">
                      <el-input
                        class="textarea"
                        type="textarea"
                        v-model="testForm.demand"
                        placeholder="请输入您的需求"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="订购数量：" prop="count">
                      <el-input
                        type="number"
                        min="1"
                        placeholder="请输入数量"
                        v-model="testForm.count"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="预算区间（元）"
                      prop="minBudget"
                      class="input-in"
                    >
                      <el-input
                        type="number"
                        id="input"
                        min="1"
                        placeholder="自定最低价"
                        v-model="testForm.minBudget"
                      ></el-input
                    ></el-form-item>
                    —
                    <el-form-item prop="maxBudget" class="input-in">
                      <el-input
                        type="number"
                        id="input"
                        min="1"
                        placeholder="自定最高价"
                        v-model="testForm.maxBudget"
                      ></el-input>
                    </el-form-item>

                    <el-form-item class="con1">
                      <el-button type="primary" @click="submitForm('ruleForm')"
                        >提交</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <p class="p-text">厂址：河南省周口市沈丘县</p>
            </div>
          </div>
        </div>
        <qz-footer></qz-footer>
      </div>
      <div class="qz-service-win">
        <img
          v-if="isMobile"
          class="msg-btn"
          @click="showServiceForm"
          src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/message.png"
          alt=""
        />

        <qz-service
          v-show="formVisible"
          :closeServiceForm="closeServiceForm"
        ></qz-service>
      </div>
      <qz-right-win></qz-right-win>
    </div>
  </div>
</template>
<script lang="ts">
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
import { sendRequest } from "../../api/contact";
import { isIntegerNotMust } from "./validate.js";
import QzService from "../../components/Service.vue";
import { isMobile as checkMobile } from "../../api/device";
import { Notification } from 'element-ui'

export default {
  name: "Contact",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
    "qz-service": QzService,
  },
  data() {
    return {
      formVisible: false,
      isMobile: checkMobile(),
      testForm: {
        name: "",
        mobile: "",
        demand: "",
        count: "",
        minBudget: "",
        maxBudget: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", blur: "blur" },
          { min: 1, max: 10, message: "姓名不超过 10 个字符", blur: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", blur: "blur" },
          { min: 11, max: 11, message: "请正确输入手机号", blur: "change" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
            blur: "change",
          },
        ],
        demand: [
          { required: true, message: "请输入您的需求", blur: "blur" },
          { min: 1, max: 200, message: "需求不超过 200 个字符", blur: "blur" },
        ],
        count: [
          { required: true, message: "请输入订购数量", blur: "blur" },
          {
            validator: isIntegerNotMust,
            message: "请输入正确的数字",
            blur: "blur",
          },
        ],
        minBudget: [
          { required: true, message: "请输入最低价", blur: "blur" },
          {
            validator: isIntegerNotMust,
            message: "请输入正确的数字",
            blur: "blur",
          },
        ],
        maxBudget: [
          { required: true, message: "请输入最高价", blur: "blur" },
          {
            validator: isIntegerNotMust,
            message: "请输入正确的数字",
            blur: "tiger",
          },
        ],
      },
      erweima:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/contact/erweim.png",
    };
  },
  methods: {
    async submitForm(ruleForm) {
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          var postData = {
            name: this.testForm.name, // 姓名
            mobile: this.testForm.mobile, // 联系方式
            demand: this.testForm.demand, // 订购需求
            count: this.testForm.count, // 订购数量
            minBudget: this.testForm.minBudget, // 最小预算
            maxBudget: this.testForm.maxBudget,
          };

          let sendResult = await sendRequest(postData);
          console.log(sendResult);
          //alert("提交成功！");
          Notification.success({
            title: '提交成功！',
            message: '请等待客服处理'
          })
        } else {
          console.log("提交失败！");
          return false;
        }
      });
    },
    showServiceForm() {
      if (this.isMobile) {
        this.$router.push("/service");
      } else {
        this.formVisible = true;
      }
    },
    closeServiceForm() {
      this.formVisible = false;
    },
  },
};
</script>
<style >
.qz-contact
  .con
  .container
  .el-form-item.is-error.is-required
  /deep/
  .el-input__inner {
  border: 2px solid red;
}
.el-form-item.is-error.is-required /deep/ .el-form-item__error {
  color: red;
  font-size: 8px;
}
.qz-contact
  .con
  .container
  .el-form-item.is-error.is-required
  /deep/
  .el-textarea__inner {
  border: 2px solid red;
}
</style>
<style lang="scss" scope>
@import "../../../node_modules/swiper/dist/css/swiper.css";
@import "./index.scss";
</style>
