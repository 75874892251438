<template>
  <div id="section5" class="section fp-section" data-anchor="fifthPage">
    <!-- style="height: 100vh" -->
    <div class="section-con">
      <div class="section5-wrap">
        <div class="section-tit-en" v-if="showHeader">News information</div>
        <div class="section-tit-ch" v-if="showHeader">{{ ZHIQI_NEWS }}</div>
        <ul>
          <li
            class="news-item"
            v-for="newsItem in newsList"
            :key="newsItem.title"
          >
            <a :href="newsItem.url" target="_blank">
              <img class="news-img" :src="newsItem.src" alt="" />
              <div class="news-font">
                <div class="new-type-tit">{{ newsItem.title }}</div>
                <p>
                  <a :href="newsItem.url" target="_blank" class="">{{
                    newsItem.brief
                  }}</a>
                </p>
                <div class="new-time">
                  <img class="icon" :src="newsTime" alt="" />
                  <span>
                    {{ newsItem.createdAt | timeFormatter("YYYY年MM月DD日") }}
                  </span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ZHIQI_NEWS } from "../../../utils/constants";
export default {
  props: ["showHeader"],
  data() {
    return {
      ZHIQI_NEWS,
      newsList: [],
      newsTime:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/news-time@2x.webp",
      newsItem1:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-news1@2x.jpg",
      newsItem2:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-news2@2x.jpg",
    };
  },
  async created() {
    // this.newsList = await this.fetchNewsList();
    this.newsList = [
      {
        title: "第八届中国行业影响力品牌峰会",
        brief:
          "2021年是“十三五”圆满收官、“十四五”全面擘画之年，在全面建成小康社会的开局之年，也是开启新的百年征程之年，为鼓励中国民营企业勇于开拓、大胆创新、爱国敬业的企业家精神，“奋进新百年·开启新征程”第八届中国行业影响力品牌峰会将于2021年7月召开。",
        createdAt: "2021-07-14T00:00:00.000Z",
        url: "https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkyNzA2ODc4Ng==&action=getalbum&album_id=2386096229111054337#wechat_redirect",
        src: this.newsItem1,
      },
      {
        title: "全国政协委员曾蓉：呼吁校服行业加强科学管理",
        brief:
          "近日，全国政协委员、四川民革专职副主委曾蓉建议“坚持校服行业的科学管理；充分发挥市场在校服配置中的关键作用；解除类似‘最高限价’、‘本地设厂’等不合理规定，让校服在各个区域自由交易，以公正监管促进优胜劣汰。”",
        createdAt: "2021-07-14T00:00:00.000Z",
        url: "/news/1",
        src: this.newsItem2,
      },
    ];
  },
};
</script>

<style lang="scss">
$themeSwiperBg: #845953;
$theme: #5a1b1b;

#section5 {
  padding: 20px 0 10px;
  margin: 0 90px;
  // .section-con {
  //   margin-top: 80px;
  // }

  .section-tit-en,
  .section-tit-ch {
    text-align: center;
  }

  .news-item {
    display: flex;
    padding: 20px 0;
    border-top: 1px solid #d1d1d1;
    &:first-child {
      border: none;
    }
    a {
      display: flex;
    }
    p {
      font-size: 12px;
      margin-top: 12px;
      flex: 1;
    }
  }
  .news-img {
    flex: none;
    width: 238px;
    height: 158px;
    margin-right: 14px;
  }
  .news-font {
    display: flex;
    flex-direction: column;
  }
  .new-type-tit {
    color: $theme;
    font-size: 16px;
  }
  .new-time {
    font-size: 12px;
    color: #666;
    display: flex;
    align-items: center;
    .icon {
      flex: none;
      width: 12px;
      height: 12px;
      margin-right: 6px;
      // background: url(https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/news-time@2x.png) no-repeat
      //   center / contain;
    }
  }
}
</style>