export function isIntegerNotMust(rule, value, callback) {
    if (!value) {
     callback();
    }
    setTimeout(() => {
     if (!Number(value)) {
      callback(new Error('请输入正整数'));
     } else {
      const re = /^[0-9]*[1-9][0-9]*$/;
      const rsCheck = re.test(value);
      if (!rsCheck) {
       callback(new Error('请输入正整数'));
      } else {
       callback();
      }
     }
    }, 1000);
   }