<template>
  <el-form
    class="container"
    ref="afterSaleForm"
    :model="afterSaleForm"
    :rules="rules"
  >
    <el-form-item label="您的姓名:" prop="name">
      <el-input
        placeholder="公司或个人姓名"
        v-model="afterSaleForm.name"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系方式:" prop="mobile">
      <el-input
        placeholder="请输入手机号"
        v-model="afterSaleForm.mobile"
      ></el-input>
    </el-form-item>
    <el-form-item label="问题描述:" prop="description">
      <el-input
        class="textarea"
        type="textarea"
        v-model="afterSaleForm.description"
        placeholder="请输入您的问题"
      ></el-input>
    </el-form-item>
    <el-form-item class="con1">
      <el-button type="primary" @click="submitForm">立即提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { afterSale } from "@/api/contact";
import { isMobile } from "@/api/device";
import { Notification } from 'element-ui'

export default {
  data() {
    return {
      afterSaleForm: {
        name: "",
        mobile: "",
        description: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", blur: "blur" },
          { min: 2, max: 10, message: "长度在 3 到 5 个字符", blur: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", blur: "blur" },
          { min: 11, max: 11, message: "请正确输入手机号", blur: "change" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
            blur: "change",
          },
        ],
        description: [
          { required: true, message: "请输入您的问题", blur: "blur" },
          { min: 0, max: 100, message: "长度在 3 到 5 个字符", blur: "blur" },
        ],
      },
    }
  },
  methods: {
    async submitForm() {
      if (!this.$refs.afterSaleForm) {
        console.log("发生错误");
        alert("请重试");
        return;
      }
      this.$refs.afterSaleForm.validate(async (valid) => {
        if (valid) {
          const postData = {
            name: this.afterSaleForm.name, // 姓名
            mobile: this.afterSaleForm.mobile, // 联系方式
            description: this.afterSaleForm.description //问题描述
          };

          const res = await afterSale(postData);
          console.log("afterSale res:", res);
          // alert("提交成功!");
          Notification.success({
            title: "提交成功！",
            message: "请等待客服处理"
          })

          setTimeout(() => {
            isMobile() && this.$router.go(-1);
          }, 1000);
        } else {
          console.log("提交失败！");
          // Notification.error({
          //   title: "提交失败！",
          //   message: "请刷新重试"
          // })
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss">
$errorColor: #f56c6c;
.el-form.container {
  .el-form-item__error {
    color: $errorColor;
  }
  .el-form-item {
    font-size: 12px;
    margin-bottom: 12px;
    .el-input__inner,
    .el-textarea__inner {
      &:focus,
      &:focus-visible {
        border-color: #409eff;
        outline: none;
      }
    }
    &:last-child {
      margin-top: 24px;
      margin-bottom: 14px;
      text-align: center;
    }
    &.is-error {
      .el-input__inner,
      .el-textarea__inner {
        border-color: $errorColor;
      }
    }
  }
  .el-input__inner,
  .el-textarea__inner {
    width: 100%;
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    padding: 9px 7px;
    margin-top: 8px;
    box-sizing: border-box;
    &::placeholder {
      color: #c7c8c8;
    }
  }
  .el-textarea {
    font-size: 0;
  }
  .el-textarea__inner {
    font-size: 12px;
    height: 230px;
    letter-spacing: 1px;
    white-space: pre-wrap;
  }
  .el-button {
    background-color: #901c1c;
    color: #fff;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 4px;
  }
}

@media screen and (max-width: 768px) {
  .el-form.container {
    .el-form-item {
      font-size: 14px;
      margin-bottom: 16px;
      &:last-child {
        margin-top: 30px;
        margin-bottom: 0;
      }
    }
    .el-input__inner,
    .el-textarea__inner {
      width: 100%;
      border: 1px solid #b2b2b2;
      border-radius: 4px;
      padding: 9px 7px;
      margin-top: 10px;
      box-sizing: border-box;
      &::placeholder {
        color: #c7c8c8;
      }
    }
    .el-textarea__inner {
      font-size: 14px;
      height: 276px;
      letter-spacing: 1px;
      white-space: pre-wrap;
    }
    .el-button {
      width: 124px;
      height: 36px;
      border-radius: 12.5px;
    }
  }
}
</style>