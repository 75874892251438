import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import "./styles/index.scss";
import dayjs from "dayjs";
import { Form, FormItem, Input, Button } from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false;
Vue.use(Form).use(FormItem).use(Input).use(Button);

Vue.filter("timeFormatter", (dt, pattern = "YYYY年MM月") => {
  return dayjs(dt).format(pattern);
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
