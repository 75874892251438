<template>
  <div class="index-header">
    <div class="header">
      <div class="header-con clearfix">
        <div class="ed-logo">
          <a href="/">
            <img :src="qzLogo" />
          </a>
        </div>
        <div class="ed-nav" :class="{ active: isMenuOpen }">
          <div class="ed-nav-link">
            <ul class="clearfix">
              <li><a href="/">我的首页</a></li>
              <li>
                <a href="javascript:;">{{ ZHIQI_STORY }}</a>
                <dl>
                  <dd>
                    <router-link to="/vision">使命愿景</router-link>
                  </dd>
                  <dd>
                    <router-link to="/original">品牌初心</router-link>
                  </dd>
                  <dd>
                    <router-link to="/respon">品牌责任</router-link>
                  </dd>
                </dl>
              </li>
              <li>
                <router-link to="/Product">热销订做</router-link>
              </li>
              <li>
                <router-link to="/Partner">合作伙伴</router-link>
              </li>
              <li>
                <!-- <a
                  href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkyNzA2ODc4Ng==&action=getalbum&album_id=2386096229111054337#wechat_redirect"
                  target="_blank"
                  >{{ ZHIQI_NEWS }}</a
                > -->
                <router-link to="/news">{{ ZHIQI_NEWS }}</router-link>
              </li>
              <li>
                <router-link to="/Contact">联系我们</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="mobile-menu">
          <a
            href="javascript:;"
            :class="{ active: isMenuOpen }"
            @click="openMenu"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ZHIQI_NEWS, ZHIQI_STORY } from "../utils/constants";
export default {
  data() {
    return {
      ZHIQI_NEWS,
      ZHIQI_STORY,
      qzLogo:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/logo@2x.webp",
      isMenuOpen: false,
    };
  },
  methods: {
    openMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style lang="scss">
$headerBg: #5a1b1b;
$linkNav: #fff;

.header {
  width: 100%;
  position: relative;
  z-index: 99;
  background: $headerBg;
}

.header.style1 .ed-logo img.logo-1 {
  opacity: 0;
}

.header.style1 .ed-logo img.logo-2 {
  opacity: 1 !important;
}

.header.style1 .ed-nav .ed-nav-link ul li > a {
  color: #fff !important;
}

.header.style2 .ed-logo img.logo-1 {
  opacity: 0;
}

.header.style2 .ed-logo img.logo-2 {
  opacity: 1 !important;
}

.header .header-con {
  padding: 35px 50px 0;
}

.header .header-con .ed-logo {
  float: left;
}

.header .header-con .ed-logo a {
  display: block;
  position: relative;
}

.header .header-con .ed-logo a img {
  display: block;
  width: 220px;
  // height: 43px;
  transition: all 0.3s;
}

.header .header-con .ed-logo a img.logo-2 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.header .header-con .ed-nav {
  float: right;
}

.header .header-con .ed-nav .ed-nav-link {
  float: left;
  margin-top: 20px;
}

.header .header-con .ed-nav .ed-nav-link ul li {
  float: left;
  margin: 0 10px;
  position: relative;
}

.header .header-con .ed-nav .ed-nav-link ul li:hover dl {
  display: block;
}

.header .header-con .ed-nav .ed-nav-link ul li > a {
  display: block;
  font-size: 24px;
  color: $linkNav;
  padding: 0 20px 30px;
  transition: all 0.3s;
}

.header .header-con .ed-nav .ed-nav-link ul li dl {
  display: none;
  position: absolute;
  left: 50%;
  width: 134px;
  transform: translateX(-50%);
  padding: 6px 0;
  background: $headerBg;
  background-size: 33px;
  border-radius: 15px;
  opacity: 0.85;

  top: 40px;
}

.header .header-con .ed-nav .ed-nav-link ul li dl::before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  // background: url("https://images.pexels.com/photos/10458845/pexels-photo-10458845.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
  //   no-repeat center;
  // background-size: 100%;
  position: absolute;
  top: -17px;
  left: 50%;
  margin-left: -7px;

  border: 7px solid transparent;
  border-bottom: 10px solid rgba(#5a1b1b, 0.85);
}

.header .header-con .ed-nav .ed-nav-link ul li dl dd {
  text-align: center;
  border-radius: 20px;
}

.header .header-con .ed-nav .ed-nav-link ul li dl dd:hover {
  // background: rgba(39, 54, 127, 0.7);
  background: rgba(90, 27, 27, 0.7);
}

.header .header-con .ed-nav .ed-nav-link ul li dl dd a {
  display: block;
  font-size: 22px;
  color: #fff;
  padding: 20px 0;
}

.header .header-con .mobile-menu {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.header .header-con .mobile-menu a {
  display: inline-block;
  width: 32px;
  height: 26px;
  background: url("https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/header-menu@2x.webp")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
}

.header .header-con .mobile-menu a.active {
  width: 23px;
  height: 23px;
  background: url("https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/header-menu-close@2x.webp")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
}

.inside-header {
  background: #171755;
  position: relative;
  z-index: 2;
}

.inside-header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
}

.inside-header .header .header-con {
  max-width: 1200px;
  padding: 10px;
  margin: 0 auto;
}

.inside-header .header .header-con .ed-logo {
  margin-top: 8px;
}

.inside-header .header .header-con .ed-logo a img {
  width: 120px;
}

.inside-header .header .header-con .ed-logo a img.logo-1 {
  opacity: 0;
}

.inside-header .header .header-con .ed-logo a img.logo-2 {
  opacity: 1;
}

.inside-header .header .header-con .ed-nav .ed-nav-link {
  margin-top: 12px;
}

.inside-header .header .header-con .ed-nav .ed-nav-link ul li > a {
  font-size: 18px;
  color: #fff;
  padding: 0 20px 13px;
}

.inside-header .header .header-con .ed-nav .ed-nav-link ul li dl {
  width: 180px;
  top: 47px;
}

.inside-header .header .header-con .ed-nav .ed-nav-link ul li dl dd a {
  font-size: 16px;
  padding: 12px 0;
}

.header .header-con .ed-nav.active {
  display: block;
  .ed-nav-link ul li > a {
    color: white;
  }
}
</style>