<template>
  <!-- h5的html代码 -->
  <div v-if="flag" class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <div class="yemian one">
         <div class="inside-title">
        <div class="title-en">
          <span class="line line1"></span>Brand Responsibility<span
            class="line line2"
          ></span>
        </div>
        <div class="title-ch">
          <h1>品牌责任</h1>
        </div>
      </div>
        <div class="item" v-for="(item, index) in imgList" :key="index">
          <div class="tupian">
            <span>{{ imgList[index].titl }}</span>
            <img :src="imgList[index].imgUrl" />
          </div>
          <p>{{ imgList[index].title }}</p>
        </div>
      </div>
      <!-- 品牌责任-->
      <div class="yemian two"></div>
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>

  <!-- pc的html代码 -->
  <div v-else class="qz-contact">
    <div class="pageCon">
      <qz-header></qz-header>
    </div>
    <div class="main">
      <div class="yemian one">
        <div class="item" v-for="(item, index) in imgList" :key="index">
          <div class="tupian">
            <span>{{ imgList[index].titl }}</span>
            <img :src="imgList[index].imgUrl" />
          </div>
          <p>{{ imgList[index].title }}</p>
        </div>
      </div>
      <!-- 品牌责任-->
      <div class="yemian two">
        <div class="inside-title">
          <div class="title-en">
            <span class="line line1"></span>Brand Responsibility<span
              class="line line2"
            ></span>
          </div>
          <div class="title-ch">
            <h1>品牌责任</h1>
          </div>
        </div>
      </div>
      <qz-footer></qz-footer>
    </div>
    <qz-right-win></qz-right-win>
  </div>
</template>
<script>
import QzHeader from "../../components/Header.vue";
import QzFooter from "../../components/Footer.vue";
import QzRightWin from "../../components/RightWin.vue";
export default {
  name: "Respon",
  components: {
    "qz-header": QzHeader,
    "qz-footer": QzFooter,
    "qz-right-win": QzRightWin,
  },

  data() {
    return {
      flag: false,
      currentIndex: -1,
      imgList: [
        {
          titl: "创新",
          imgUrl: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/respon/pic4.png",
          title: "引领行业品质创新",
        },
        {
          titl: "发展",
          imgUrl: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/respon/pic5.png",
          title: "引领行业未来探索和发展",
        },
        {
          titl: "公益",
          imgUrl: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/respon/pic6.png",
          title: "引领行业创造更多社会价值",
        },
      ],
    };
  },
  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },
};
</script>
<style scoped lang="scss">
@import "../../../node_modules/swiper/dist/css/swiper.css";

.qz-contact {
  .pageCon {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 8;
  }

  .one {
    width: 100%;
    height: 810px;
    background: #dad5cb;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 118px;
  }
  .one .item {
    margin-right: 23px;
    text-align: center;
  }
  .one .item img {
    display: inline-block;
    width: 368px;
    height: 562px;
  }
  .one .item span {
    display: inline-block;
    width: 169px;
    height: 51px;
    font-size: 23px;
    color: #ffffff;
    text-align: center;
    line-height: 51px;
    border: 2px solid #ffffff;
    border-radius: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -84.5px;
    margin-top: -25.5px;
  }
  .item .tupian {
    position: relative;
  }
  .item p {
    color: #666666;
    font-size: 23px;
    padding-top: 10px;
  }
  .two {
    width: 100%;
    height: 810px;
    background: url("https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/respon/bg.png") no-repeat center /
      cover;
    position: relative;
    top: 118px;
  }
  .inside-title .title-en .line {
    width: 58px;
    height: 1px;
    background: #5a1b1b;
    display: inline-block;
    vertical-align: middle;
    margin: 0 38px;
  }
  .inside-title .title-en,
  .inside-title .title-ch {
    text-align: center;
    font-size: 23px;
    font-family: jiangchengyuanti500W;
    font-weight: normal;
    color: #5a1b1b;
    line-height: 48px;
  }
}
@media screen and (max-width: 768px) {
  .main {
    .one,
    .two {
      height: 100%;
      flex-direction: column;
      top: 0px;
    }
    .one .item {
      margin: 0 auto;
      margin-bottom: 40px;
    }
    .one .item img {
      width: 182px;
      height: 281px;
    }
    .one .inside-title{
      padding-top: 80px;
      padding-bottom: 16px;
    }
    .one .item span {
      width: 84.5px;
      height: 25.5px;
      font-size: 11.5px;
      line-height: 25.5px;
      left: 74%;
    }
    .one .item p {
      font-size: 11.5px;
    }
    .main .two .inside-title {
      position: relative;
      top: 10px;
    }
    .inside-title .title-en,
    .inside-title .title-ch {
      font-size: 14px;
      line-height: 28px;

      .line {
        width: 29px;
        margin: 0 19px;
      }
    }
  }
}
</style>
