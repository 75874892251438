<template>
  <div id="section1" class="section fp-section" data-anchor="firstPage">
    <!-- style="height: 100vh" -->
    <div class="section-con">
      <div class="section1-banner">
        <div
          class="
            swiper-container
            swiper-container-horizontal
            swiper-container-autoheight
          "
        >
          <div
            class="swiper-wrapper"
            style="
              transform: translate3d(-2880px, 0px, 0px);
              transition-duration: 0ms;
            "
          >
            <!-- height: 739px; -->
            <div class="swiper-slide">
              <div
                class="swiper-slide-home-banner"
                :style="{
                  backgroundImage: `url(${homeBanner1})`,
                }"
              ></div>
              <!-- width: '1440px', -->
            </div>
          </div>
        </div>
      </div>
      <div class="section1-font">
        <div class="t">
          <div class="c">
            <img class="slogan" :src="homeSlogan" />
          </div>
        </div>
      </div>
      <div class="section1-bottom">
        <ul class="clearfix">
          <li>
            <div class="t">
              <a href="/Product"
                ><h1>校服款式及定制</h1>
                <p>School uniform style and customization</p>
                <i></i>
              </a>
            </div>
          </li>

          <li>
            <div class="t">
              <a href="/Welfare"
                ><h1>琪志公益</h1>
                <p>Qizhi public welfare</p>
                <i></i>
              </a>
            </div>
          </li>
          <li>
            <div class="t">
              <a href="javascript:;" @click="showServiceForm">
                <h1>售后服务</h1>
                <p>After-sales service</p>
                <i></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  props: ["showServiceForm"],
  data() {
    return {
      homeSlogan: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-slogan@2x.webp",
      homeBanner1: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/home-banner@2x.webp",
      arrowDown: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/arrow-down@2x.webp",
    }
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      new Swiper(".swiper-container", {
        //设置轮播的循环方式
        loop: true,

        //设置自动播放间隔时间
        // autoplay: 2000,

        // 轮播效果,默认为平滑轮播
        effect: "slide",

        //分页器
        pagination: ".swiper-pagination",

        //前进后退按钮
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",

        // 用户中断轮播后续播
        autoplayDisableOnInteraction: false,
      });
    },
  },
}
</script>

<style lang="scss">
#section1 {
  min-height: 100vh;
  .swiper-slide-home-banner {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .swiper-slide-home-banner::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#030100, 0.3);
  }
  .section-con {
    min-height: 100vh;
  }
}
.section-con .section1-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.section-con .section1-banner .swiper-container {
  height: 100%;
}
.section-con .section1-banner .swiper-slide {
  width: 100%;
  height: 100%;
}

.section-con .section1-font {
  width: 76%;
  height: 85vh;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}

.section-con .section1-font img {
  display: block;
  width: 556px;
  // height: 97.5px;
}

.section-con .section1-bottom {
  height: 15vh;
  position: relative;
  z-index: 9;
}

.section-con .section1-bottom ul {
  width: 100%;
  height: 100%;
  border-top: 1px solid #fff;
}

.section-con .section1-bottom ul li {
  float: left;
  width: 33.33%;
  height: 100%;
  text-align: center;
  position: relative;
}

.section-con .section1-bottom ul li:nth-child(1):before,
.section-con .section1-bottom ul li:nth-child(2):before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.section-con .section1-bottom ul li .t {
  width: 100%;
  height: 100%;
}

.section-con .section1-bottom ul li a {
  display: table-cell;
  vertical-align: middle;
}

.section-con .section1-bottom ul li a h1 {
  display: block;
  font-size: 30px;
  color: #fff;
  font-weight: 500;
}

.section-con .section1-bottom ul li a p {
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
}

.section-con .section1-bottom ul li a i {
  display: inline-block;
  width: 24px;
  height: 14px;
  background: url("https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/arrow-down@2x.webp")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
  margin-top: 10px;
}
</style>