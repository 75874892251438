<template>
  <div id="section6" class="section fp-section" data-anchor="sixPage">
    <!-- style="height: 100vh" -->
    <div class="section-con">
      <div id="section6" class="section fp-section" data-anchor="seventhPage">
        <!-- style="height: 100vh" -->
        <div class="section-con">
          <div class="section-footer">
            <div class="footer-nav">
              <div class="footer">
                <div class="footer-con clearfix">
                  <div class="footer-r">
                    <dl>
                      <dt>{{ ZHIQI_STORY }}</dt>
                      <dd>
                        <router-link to="/vision">使命愿景</router-link>
                      </dd>
                      <dd>
                        <router-link to="/original">品牌初心</router-link>
                      </dd>
                      <dd>
                        <router-link to="/respon">品牌责任</router-link>
                      </dd>
                    </dl>
                    <dl>
                      <dt>热销订做</dt>
                      <dd>
                        <router-link to="/Product">热销订做</router-link>
                      </dd>
                    </dl>
                    <dl>
                      <dt>合作伙伴</dt>
                      <dd>
                        <router-link to="/Partner">合作伙伴</router-link>
                      </dd>
                    </dl>
                    <dl>
                      <dt>{{ ZHIQI_NEWS }}</dt>
                      <dd>
                        <a
                          href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkyNzA2ODc4Ng==&action=getalbum&album_id=2386096229111054337#wechat_redirect"
                          target="_blank"
                          >{{ ZHIQI_NEWS }}</a
                        >
                      </dd>
                    </dl>
                    <!-- <dl>
                      <dt>信息公开</dt>
                      <dd>
                         <router-link to="/">企业基本信息</router-link>
                      </dd>
                      <dd>
                         <router-link to="/">企业重大事项</router-link>
                      </dd>
                      <dd>
                         <router-link to="/">履行社会责任</router-link>
                      </dd>
                    </dl> -->
                  </div>
                  <div class="footer-l">
                    <div class="company-name">上海琪志服饰有限公司</div>
                    <div class="company-tel">
                      <p>
                        <span>电话：021-58598570</span>
                        <span style="margin-left: 3em">400-0676-580</span>
                        <span>地址：上海市浦东新区施湾二路688号</span>
                        <span
                          >官方合作邮箱：<a href="mailto:qz0207@126.com"
                            >qz0207@126.com</a
                          ></span
                        >
                      </p>
                    </div>
                    <div class="company-copy">
                      <p>
                        <a href="https://beian.miit.gov.cn" style="color: white"
                          >沪ICP备10027619号-6</a
                        >
                      </p>
                    </div>
                    <div class="company-code">
                      <span><img :src="qr1" /><b>琪志微信公众号</b></span>
                      <span><img :src="qr2" /><b>联系我们</b></span>
                      <span
                        ><img :src="qr3" /><b>曙光公益</b
                        ><b style="margin-top: 0">微信公众号</b></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ZHIQI_NEWS, ZHIQI_STORY } from "../utils/constants";
export default {
  data() {
    return {
      ZHIQI_NEWS,
      ZHIQI_STORY,
      qr1: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/qr1@2x.webp",
      qr2: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/qr2@2x.webp",
      qr3: "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/qr3@2x.webp",
    };
  },
};
</script>

<style lang="scss">
.footer-nav {
  background-color: #89806c;
}

.inside-footer {
  padding-bottom: 80px;
  background-color: #10195e;
}

.inside-footer .footer {
  max-width: 1200px;
  padding: 60px 10px 0;
}

.inside-footer .footer .footer-con {
  padding-top: 40px;
}

.inside-footer .footer-quick {
  max-width: 1200px;
  padding: 80px 10px 0;
}

.inside-footer .footer-quick ul {
  margin-top: 0;
}

.inside-footer .footer-quick ul li a {
  font-size: 16px;
  line-height: 40px;
}

.inside-footer .footer-quick ul li a i {
  width: 18.5%;
  height: 40px;
}

.footer-quick {
  width: 84%;
  margin: 0 auto;
  padding-top: 130px;
}

.footer-quick ul {
  margin-top: 10vh;
}

.footer-quick ul li {
  float: left;
  width: 19%;
  margin: 0 0.5%;
  background: #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-align: center;
}

.footer-quick ul li a {
  display: block;
  font-size: 24px;
  color: #10195e;
  padding: 20px;
  line-height: 62px;
  text-indent: -2em;
}

.footer-quick ul li a i {
  display: inline-block;
  float: left;
  width: 22.5%;
  height: 60px;
  margin-left: 22%;
}

.footer-quick ul li a i.f-icon01 {
  background: url("https://images.pexels.com/photos/10458845/pexels-photo-10458845.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
}

.footer-quick ul li a i.f-icon02 {
  background: url("https://images.pexels.com/photos/10458845/pexels-photo-10458845.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
}

.footer-quick ul li a i.f-icon03 {
  background: url("https://images.pexels.com/photos/10458845/pexels-photo-10458845.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
}

.footer-quick ul li a i.f-icon04 {
  background: url("https://images.pexels.com/photos/10458845/pexels-photo-10458845.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
}

.footer-quick ul li a i.f-icon05 {
  background: url("https://images.pexels.com/photos/10458845/pexels-photo-10458845.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
    no-repeat center;
  -moz-background-size: 100%;
  background-size: 100%;
}

.footer {
  width: 84%;
  margin: 0 auto;
  padding-top: 10vh;
  background-color: #89806c;
}

.footer .footer-con {
  padding-top: 8vh;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.footer .footer-con .footer-l {
  float: left;
  width: 25%;
}

.footer .footer-con .footer-l .company-name {
  font-size: 18px;
  color: #fff;
}

.footer .footer-con .footer-l .company-tel {
  margin-top: 5vh;
}

.footer .footer-con .footer-l .company-tel p {
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  line-height: 24px;
}

.footer .footer-con .footer-l .company-tel p span {
  display: block;
}

.footer .footer-con .footer-l .company-tel p span a {
  color: #fff;
}

.footer .footer-con .footer-l .company-copy {
  margin-top: 5vh;
}

.footer .footer-con .footer-l .company-copy p {
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  line-height: 24px;
}

.footer .footer-con .footer-l .company-code {
  margin-top: 4vh;
  overflow: hidden;
}

.footer .footer-con .footer-l .company-code span {
  float: left;
  display: block;
  max-width: 31%;
  text-align: center;
  margin-right: 3%;
  &:last-child {
    margin-right: 0;
  }
}

.footer .footer-con .footer-l .company-code span img {
  display: inline-block;
  width: 80px;
}

.footer .footer-con .footer-l .company-code span b {
  font-size: 12px;
  text-align: center;
  color: white;
  margin-top: 10px;
  display: block;
  opacity: 0.5;
}

.footer .footer-con .footer-r {
  float: right;
  width: 75%;
}

.footer .footer-con .footer-r dl {
  float: left;
  width: 13%;
  padding-left: 7%;
  text-align: left;
}

.footer .footer-con .footer-r dl dt {
  font-size: 16px;
  color: #fff;
  margin-bottom: 5vh;
}

.footer .footer-con .footer-r dl dd {
  margin-top: 4vh;
}

.footer .footer-con .footer-r dl dd a {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  // line-height: 20px;
  line-height: 1;
}

.footer .footer-con .footer-r dl dd a:hover {
  opacity: 1;
}
</style>