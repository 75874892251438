<template>
  <div class="qz-home">
    <div class="pageCon">
      <qz-header></qz-header>
      <div>
        <router-view
          :showServiceForm="showServiceForm"
          :closeServiceForm="closeServiceForm"
        ></router-view>
        <qz-footer></qz-footer>
      </div>
      <qz-right-win></qz-right-win>
      <!-- <div class="index-goTop" style="display: block">
        <a href="#firstPage"></a>
      </div> -->
    </div>
    <div class="qz-service-win">
      <img
        v-if="isMobile"
        class="msg-btn"
        @click="showServiceForm"
        src="https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/images/vision/message.png"
        alt=""
      />

      <qz-service
        v-show="formVisible"
        :closeServiceForm="closeServiceForm"
      ></qz-service>
    </div>
  </div>
</template>

<script>
import QzHeader from "@/components/Header.vue";
import QzFooter from "@/components/Footer.vue";
import QzRightWin from "@/components/RightWin.vue";
import QzService from "@/components/Service.vue";
import { isMobile as checkMobile } from "@/api/device";

export default {
  name: "Layout",
  components: {
    "qz-footer": QzFooter,
    "qz-header": QzHeader,
    "qz-right-win": QzRightWin,
    "qz-service": QzService,
  },
  data() {
    return {
      formVisible: false,
      isMobile: checkMobile()
    }
  },
  methods: {
    showServiceForm() {
      if (this.isMobile) {
        this.$router.push("/service")
      } else {
        this.formVisible = true
      }
    },
    closeServiceForm() {
      this.formVisible = false;
    }
  }
}
</script>

<style lang="scss">
.qz-service-win {
  .msg-btn {
    width: 34px;
    height: 34px;
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 10;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>